import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/bin/staticsites/ss-oryx/app-int/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ArticleCard = makeShortcode("ArticleCard");
const Aside = makeShortcode("Aside");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page is meant to demonstrate some of the components in action. You can check out the markdown used for this page `}<a parentName="p" {...{
          "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/master/packages/example/src/pages/demo/index.mdx"
        }}>{`on github`}</a>{`.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Building bonds</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Global accessibility standards</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Small anchor links</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Videos</AnchorLink>
    </AnchorLinks>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Getting started" href="/getting-started" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/article.png",
              "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/article.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/article.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/article.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/article.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Theme configuration" href="/guides/configuration" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/article.png",
              "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/article.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/article.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/article.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/article.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
        <ArticleCard title="Contributions" href="/contributions" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACB0lEQVQoz12TS2/aQBRGWTTbqDie8fghSIRpkiJCgrBE8WvGNlRNaVTaLtquiyJ2CAK/qFIX/ZFf7xhDaBZHd2RrztxvHjVmmrhoXaJ79w5MeOB2A5bTPMDcFhzHRTgaIY6ikjAMkecZfv74jq9fZoii8ECNMYZmo4G311doNhsQFodh1HF2ZuwwXsO2BeI0gyw+IKGqUfkYHz99xv3DN8RxQjJaLI5RM6lD3/cRBAF6vVuih263W3GDTqcD13EQJRnSbIJUjUtBnEhaJEeiJjR+KWy3keUFpKKVsxxKV6VQFPRNSnieS0JVTtAyWdyT/D3FD5HIjITpsZDBb50jTwKoaEAEJElK0XA4xGAwgOdqoaSoshQkqaqqrIRHHXLTgNu6RTt5hB/PiV+4vgloTy/pMBxwzmELgYjiyfGUBAVFVSU6rpw8/C+0zFPU22O8Kv7iJP+Dk+w36l4XpnFKMguWZVFkDwnF0lsipUJakeV5WfeyXYc0weIMgtUhuFFiWQJC2GDMRL/fx3Q6xXK5xNN6jdVqhc3mCdvtFvP5HJl6Fupa0zLmXIB5V+Ud1DDvDbjrg9F2HAvXJNxsNlgsFpjNZqVE38m9bBeZhKXIOQcXLrhFnemxvuD07zny7qA0erI+sOPODpF3wiZ0dP0qmNumsaiEnGLTodg2RvRSouqV7Ce/lGn+AcEOYKn+FnaEAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/article.png",
              "srcSet": ["/static/dc51d23a5322c2511205c8c525bbe8ee/7fc1e/article.png 288w", "/static/dc51d23a5322c2511205c8c525bbe8ee/a5df1/article.png 576w", "/static/dc51d23a5322c2511205c8c525bbe8ee/3cbba/article.png 1152w", "/static/dc51d23a5322c2511205c8c525bbe8ee/362ee/article.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
    </Row>
    <PageDescription mdxType="PageDescription">
      <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.`}</p>
    </PageDescription>
    <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.`}</p>
    <Row mdxType="Row">
      <Column colMd={5} colLg={8} mdxType="Column">
        <h2>{`Section heading`}</h2>
        <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.`}</p>
        <blockquote>
          <p parentName="blockquote">{`Add-on repositories, or repos, are components built for a specific product or
experience. The repos are built on top of the core Carbon repo, and enable`}</p>
        </blockquote>
        <p>{`IBMers believe in progress—that application of intelligence, reason and science can improve business, society and the human condition.`}</p>
      </Column>
      <Column colMd={2} colLg={3} offsetMd={1} offsetLg={1} mdxType="Column">
        <Aside mdxType="Aside">
          <p><strong>Good design is always<br />good design.</strong></p>
          <p>{`What we borrow from our own design history is not a mid-century aesthetic in stylistic terms, but the modernist attitudes and approach used at the time.`}</p>
        </Aside>
      </Column>
    </Row>
    <h2>{`Small anchor links`}</h2>
    <p>{`Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.`}</p>
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Header</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Header again</AnchorLink>
    </AnchorLinks>
    <h3>{`Header`}</h3>
    <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.`}</p>
    <h3>{`Header again`}</h3>
    <p>{`Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crow’s nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.`}</p>
    <ol>
      <li parentName="ol">{`This is a demonstration of an ordered list item. This list item is particularly long to demonstrate how the text wraps and aligns with the first line.`}<ol parentName="li">
          <li parentName="ol">{`This is a nested list item, it should wrap in the same way. Typically, nested list items should be of the same type (ordered, unordered) as their parent.`}</li>
        </ol></li>
      <li parentName="ol">{`Ordered list item`}</li>
      <li parentName="ol">{`Ordered list item`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`This is a demonstration of an unordered list item. This list item is particularly long to demonstrate how the text wraps and aligns with the first line.`}<ul parentName="li">
          <li parentName="ul">{`This is a nested list item, it should wrap in the same way. Typically, nested list items should be of the same type (ordered, unordered) as their parent.`}</li>
        </ul></li>
      <li parentName="ul">{`Unordered list item`}</li>
      <li parentName="ul">{`Unordered list item`}</li>
    </ul>
    <h2>{`Images`}</h2>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACBUlEQVQoz42Ty2tTQRSHpxbRv8BqxQdSF4J2Y8W9CwXX4iZt1TYqiEYLRVAxCoKg6Mb6ILgQRRDRFl2YTSlixUjFUhXb5L5y87pJqhUF64PU9PPcSQqJEnHxY5h75nzn/ObMVaZl00hePk8m61HIGHRf/4LaBYuD86i90H7qB4OjWYmZbD37HdUJi3ollnYt6pQ0SYm8rMvF8+cYuDKAl8sQjaXYfuErqgtUd1X70IU2nPipvzf1CPDZeIp6uVqxdx7bdgYIHT5CzvPIJA1yKZNI1KPtuAACVWigAlY+zF+X7C/zp5YeKNMchLX9v4ibLknHxjD9a3D4VHSZTDj03Z6hJTRHk29zAeav/n38JT8oFpZJgp/s2Ba27TAVjzM8MkrWTTA7naD/zozuUltdkKrdVKWr7oHlR0sCtLEtg7QM58H9e7S3reTq3RF2XJqT4uUKrNay6vE39dKHNFA6jItV02A6ZxMbm2Bj15B0/61yf9UBNQdrLf8D2BIqMWW4fChkuPakwGq5U3Wwek6G0XqsxOVHRTaHK89GO2sIlMor+sqMjb/n+fBjDt38iNot8U4ZmCT2Rj4zMelQzBp0nKl5hw2BkrjuJNyI3GL9mlUyjKdsOj3PlvAs0ZdpDXIdi4Rh0xH+H6B02Codvnr9lqHBh1hmnBdvUvoPyqdNDbKsimo7/A16l2Z/gnNwogAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Large blocky illustration",
        "title": "Large blocky illustration",
        "src": "/static/ae93dc374fd305c3f957d48783714dbc/3cbba/large-image.png",
        "srcSet": ["/static/ae93dc374fd305c3f957d48783714dbc/7fc1e/large-image.png 288w", "/static/ae93dc374fd305c3f957d48783714dbc/a5df1/large-image.png 576w", "/static/ae93dc374fd305c3f957d48783714dbc/3cbba/large-image.png 1152w", "/static/ae93dc374fd305c3f957d48783714dbc/0b124/large-image.png 1728w", "/static/ae93dc374fd305c3f957d48783714dbc/91583/large-image.png 1886w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h2>{`Videos`}</h2>
    <Video title="Eyes" vimeoId="310583077" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      